import React from 'react';
import IconButton from '@material-ui/core/IconButton';

const android = (props = null) => {
    return (
        <i className="devicon-android-plain colored fa-2x"></i>
    );
}

const java = (props = null) => {
    return (
        <i className="devicon-java-plain-wordmark colored fa-2x"></i>
    );
}

const javascript = (props = null) => {
    return (
        <i className="devicon-javascript-plain colored fa-2x"></i>
    );
}

const react = (props = null) => {
    return (
        <i className="devicon-react-original colored fa-2x"></i>
    );
}

const python = (props = null) => {
    return (
        <i className="devicon-python-plain colored fa-2x"></i>
    );
}

const github = (props) => {
    return (
        <IconButton href={props.link} style={{ textDecoration: 'none' }}><i className="devicon-github-plain colored fa-2x"></i></IconButton>
    );
}

const flutter = (props = null) => {
    return (
        <img src="./icons/flutter.png" alt="flutter" width="42" height="42"></img>
    );
}

const mongodb = (props = null) => {
    return (
        <i className="devicon-mongodb-plain-wordmark colored fa-2x"></i>
    );
}

const code = (props = null) => {
    return (
        <img src="./icons/code.png" alt="project-icon" width="32" height="32"></img>
    );
}

const nodejs = (props = null) => {
    return (
        <i className="devicon-nodejs-plain colored fa-2x"></i>
    );
}

const go = (props = null) => {
    return (
        <i className="devicon-go-plain colored fa-2x" style={{ color: "lightblue" }}></i>
    );
}

export const TypeIcon = (props) => {

    const icon = {
        flutter: flutter,
        python: python,
        java: java,
        react: react,
        android: android,
        javascript: javascript,
        github: github,
        nodejs: nodejs,
        mongodb: mongodb,
        go: go,
        code: code
    }
    if (props.type in icon) {
        return icon[props.type](props);
    }
    return icon['code'](props);
}

