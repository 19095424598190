import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ReactMarkdown from 'react-markdown/with-html';
import { TypeIcon } from './icons';
import { withStyles } from '@material-ui/styles/';
import { theme } from './Theme';
import { README_URL, USERNAME } from './config';

const styles = (theme) => ({
    container: {
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.only('xs')]: {
            paddingLeft: 24,
            paddingRight: 24
        }
    },
    title: {
        marginTop: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    readme: {
        marginTop: 40,
        marginBottom: 40
    },
    contentBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 16,
        paddingLeft: 32,
        paddingRight: 32
    }
});

const Project = (props) => {
    const { classes } = props;
    const [state, setState] = React.useState({ readme: '', loading: true });

    if (state.loading && !props.loading) {
        fetch(README_URL(USERNAME, props.repo.name, 'master', 'README.md'))
            .then(res => res.text())
            .then(data => { setState({ readme: data, loading: false }) });
    }

    return (
        <React.Fragment>
            <Container className={classes.container}>
                <Grid container>
                    <Grid item xs={12} md={10} lg={11} >
                        <Box className={classes.title}>
                            {props.loading ? <Skeleton width="40%" height={50} /> :
                                <h2 className="display-4 my-2 text-center text-secondary" style={{ marginLeft: "10%", fontSize: 42 }}>{props.repo.name.replace(/-/gi, ' ')}</h2>}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={2} lg={1}>
                        <Box className={classes.title}>
                            {props.loading ? <Skeleton variant="circle" width={40} height={40} /> :
                                <TypeIcon type="github" link={props.repo.html_url} />}
                        </Box>
                    </Grid>

                </Grid>

                <Box className={classes.contentBox}>
                    {props.loading ? <Skeleton variant="circle" style={{ marginRight: 16 }} width={40} height={40} /> :
                        <TypeIcon type={props.repo.topics.length > 0 ? props.repo.topics[0] : "code"} />}
                    {props.loading ? <Skeleton width="80%" /> :
                        <h3 className="lead text-center ml-1 my-1">{props.repo.description}</h3>}
                </Box>
                <Box className={classes.readme}>
                    {props.loading || state.loading ? <Skeleton height={360} width="100%" /> :
                        <Container>
                            <ReactMarkdown source={state.readme} escapeHtml={false} />
                        </Container>
                    }
                </Box>
                <Box>
                    {props.loading ? <Skeleton width={160} height={40} /> :
                        (props.repo.homepage === null ? null :
                            <Button variant="outlined" href={props.repo.homepage} class="btn btn-outline-success mt-0 mb-4">{props.repo.homepage.includes('download') ? <i className="fas fa-arrow-down"></i> : null}
                                {props.repo.homepage.includes('download') ? ` Download ${props.repo.name.replace(/-/gi, ' ')}` : ' Try out ' + props.repo.name.replace(/-/gi, ' ')}
                                {props.repo.homepage.includes('download') ? null : <i className="fas fa-arrow-right ml-1"> </i>}</Button>
                        )
                    }
                </Box>

            </Container>
            <hr />
        </React.Fragment >
    );
}


export default withStyles(styles(theme))(Project);