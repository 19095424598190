import React from 'react';
import Project from './Project';
import { REPOS_URL, SECRET_TOKEN, ACCEPT_ARG } from './config';

export class Viewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: [null, null],
            loading: true
        }

    }

    componentDidMount = () => {
        this.loadProjects();
    }

    filter = (data) => {
        const allowed = ['html_url', 'homepage', 'description', 'name', 'language', 'topics', 'private'];
        const filtered = Object.keys(data)
            .filter(key => allowed.includes(key))
            .reduce((obj, key) => { obj[key] = data[key]; return obj; }, {});
        var index = filtered['topics'].indexOf('portfolio');
        if (index !== -1) {
            filtered['topics'].splice(index, 1);
        } return filtered;
    }

    loadProjects = () => {
        fetch(REPOS_URL, {
            headers: new Headers({ 'Authorization': SECRET_TOKEN, 'Accept': ACCEPT_ARG })
        }).then(res => res.json()).then((data) => {
            console.log(data);
            var items = data.filter(item => !item['private']);
            items.sort((a, b) => b['updated_at'].localeCompare(a['updated_at']));
            items = items.filter(item => item['topics'].includes('portfolio'));
            console.log(items);
            this.setState({ projects: items.map(item => this.filter(item)), loading: false });
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.projects.map((item, idx) => <Project key={idx} loading={this.state.loading} repo={item} />)}
            </React.Fragment>
        );
    }
}